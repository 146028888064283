import React, { useState } from 'react';
import { useEffect } from 'react';
import { DislikeButtonFilled } from './DislikeButtonFilled';
import { DislikeButtonUnfilled } from './DislikeButtonUnfilled';
import { LikeButtonFilled } from './LikeButtonFilled';
import { LikeButtonUnfilled } from './LikeButtonUnfilled';

export const Reactions = ({ cod_asignacion: codigo }) => {
    const [isLiked, setIsLiked] = useState(false);
    const [isDisLiked, setIsDisLiked] = useState(false);

    useEffect(() => {
        const liked = localStorage.getItem(`${codigo}-isLiked`) === 'true' ? true : false;
        setIsLiked(liked);
        const disliked = localStorage.getItem(`${codigo}-isDisLiked`) === 'true' ? true : false;
        setIsDisLiked(disliked);
    }, [setIsLiked, setIsDisLiked]);

    const handleLike = () => {
        setIsLiked(!isLiked);
        if (!isLiked) {
            setIsDisLiked(false);
            localStorage.setItem(`${codigo}-isDisLiked`, false);
        }
        localStorage.setItem(`${codigo}-isLiked`, !isLiked);
    };

    const handleDisLike = () => {
        setIsDisLiked(!isDisLiked);
        if (!isDisLiked) {
            setIsLiked(false);
            localStorage.setItem(`${codigo}-isLiked`, false);
        }
        localStorage.setItem(`${codigo}-isDisLiked`, !isDisLiked);
    };

    return (
        <div>
            {isLiked ? <LikeButtonFilled handleLike={handleLike} /> : <LikeButtonUnfilled handleLike={handleLike} />}
            {isDisLiked ? <DislikeButtonFilled handleDisLike={handleDisLike} /> : <DislikeButtonUnfilled handleDisLike={handleDisLike} />}
        </div>
    );
};
