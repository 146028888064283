import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import config from 'config';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
// ============================|| FORM PHISICS ||============================ //

export default function FormPhisics({ setMostrarPasos, setPasos }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [enviando, setEnviando] = useState(false);
    const scriptedRef = useScriptRef();

    useEffect(() => {
        loadReCaptcha('6Ldqj4IjAAAAAA6zh0CWmo_Ap7EEluNq12nt79vu');
    }, []);

    const handleClick = () => {
        return navigate('/');
    };

    return (
        <>
            <Formik
                initialValues={{
                    numero: '',
                    token: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    numero: Yup.string().max(25).required('Número de trámite es requerido')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        // host config.server
                        setEnviando(true);
                        const datosEnviar = new FormData();
                        datosEnviar.append('codigo', values.numero);
                        datosEnviar.append('empresa', config.empresa);
                        datosEnviar.append('token', values.token);
                        await fetch(`${config.server}/service/trackingVentanilla`, {
                            method: 'POST',
                            body: datosEnviar
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.tipo_tramite[0].desc !== '' && data.bitacora.length !== 0) {
                                    setMostrarPasos(true);
                                    setPasos(data);
                                } else {
                                    setErrors({ submit: 'Error al buscar el número de trámite' });
                                }
                                setEnviando(false);
                                setStatus({ success: true });
                                setSubmitting(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                // console.log('error al borrar las variables', error);
                                setEnviando(false);
                                setStatus({ success: false });
                                setErrors({ submit: 'Error al procesar la solicitud, por favor intente mas tarde' });
                                setSubmitting(false);
                            });
                    } catch (err) {
                        console.log(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <FormControl fullWidth error={Boolean(touched.numero && errors.numero)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Número de trámite</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="number"
                                value={values.numero}
                                name="numero"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Número de trámite"
                                inputProps={{}}
                            />
                            {touched.numero && errors.numero && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.numero}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <ReCaptcha
                            action="main"
                            sitekey="6Ldqj4IjAAAAAA6zh0CWmo_Ap7EEluNq12nt79vu"
                            verifyCallback={(token) => setFieldValue('token', token)}
                        />
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}
                        <div>
                            <div>
                                <AnimateButton>
                                    <LoadingButton
                                        loading={enviando}
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Enviar
                                    </LoadingButton>
                                </AnimateButton>
                            </div>
                            <hr color="white" />
                            <div className="centerButtons">
                                <AnimateButton>
                                    <button type="button" className="btn btn-outline-primary" onClick={handleClick}>
                                        <ArrowBackIcon fontSize="large" />
                                    </button>
                                </AnimateButton>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
}
