import * as React from 'react';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { styled } from '@mui/material/styles';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import MainCard from 'ui-component/cards/MainCard';
import { LikeButtonUnfilled } from 'ui-component/LikeButtonUnfilled';
import { DislikeButtonUnfilled } from 'ui-component/DislikeButtonUnfilled';
import { useState } from 'react';
import { useEffect } from 'react';
import { clearData, getTimeOfProcedure } from 'utils/orderElectronics';
import { Reactions } from 'ui-component/Reactions';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary.main} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -195
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary.main} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

export default function StepsTimelineElectronics({ timeline }) {
    const [cleanData, setCleanData] = useState([]);
    const [timeStamp, setTimeStamp] = useState('0 día(s), 0 hora(s) y 0 minuto(s)');
    useEffect(() => {
        const data = clearData(timeline);
        setCleanData(data);
        setTimeStamp(getTimeOfProcedure(data));
    }, [setCleanData, timeline]);

    return (
        <div>
            {cleanData.map((item, i) => (
                <TimelineItem key={i}>
                    <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.primary">
                        {item.title}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot sx={{ minWidth: '35px', display: 'flex', justifyContent: 'center' }} color="primary">
                            {i + 1}
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '1px', px: 2 }}>
                        {item.logs.map((log, j) => (
                            <CardWrapper
                                sx={{
                                    marginBottom: 2,
                                    backgroundColor: log.color,
                                    textAlign: 'left'
                                }}
                                contentSX={{ paddingLeft: 1, paddingRight: 0, paddingTop: 0, paddingBottom: '0px !important' }}
                                content={true}
                                key={`item-${i}-${j}`}
                            >
                                <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                    {log.user}
                                </Typography>
                                <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                    {log.start}
                                </Typography>
                                <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                    {log.end}
                                </Typography>
                                <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                    {log.comentario}
                                </Typography>
                                <div className="likedButtons">
                                    <Reactions cod_asignacion={log.cod_asignacion} />
                                </div>
                            </CardWrapper>
                        ))}
                    </TimelineContent>
                </TimelineItem>
            ))}
            <Typography variant="h3" textAlign="center">
                Tiempo total: {timeStamp}
            </Typography>
        </div>
    );
}
