import { useEffect } from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getActiveStep, getTimeOfProcedure, parseData, timeWithoutRejection } from 'utils/orderSteps';

import StepsTimeline from './StepTimeline';
import Timeline from '@mui/lab/Timeline';

export default function Bitacora({ bitacora, type }) {
    const [parsedBitacora, setParsedBitacora] = useState([]);
    const [stepActive, setStepActive] = useState(1);
    const [timeStamp, setTimeStamp] = useState('0 día(s), 0 hora(s) y 0 minuto(s)');
    const [timeWithoutRejections, setTimeWithoutRejections] = useState('0 día(s), 0 hora(s) y 0 minuto(s)');

    useEffect(() => {
        try {
            const steps = parseData(bitacora, type);
            setParsedBitacora(steps);
            setStepActive(getActiveStep(steps));
            const time = getTimeOfProcedure(steps);
            setTimeStamp(time);
            setTimeWithoutRejections(timeWithoutRejection(steps, time));
        } catch (err) {
            console.log(err);
            setParsedBitacora([]);
            setStepActive(1);
            setTimeStamp('0 día(s), 0 hora(s) y 0 minuto(s)');
            setTimeWithoutRejections('0 día(s), 0 hora(s) y 0 minuto(s)');
        }
    }, [bitacora]);

    return (
        <Box sx={{ maxWidth: 800 }}>
            <Timeline position="alternate">
                <StepsTimeline items={parsedBitacora} stepActive={stepActive} />
            </Timeline>
            {timeWithoutRejections !== timeStamp && (
                <Typography variant="h3" textAlign="center">
                    Tiempo sin rechazo: {timeWithoutRejections}
                </Typography>
            )}
            <Typography variant="h3" textAlign="center">
                Tiempo total: {timeStamp}
            </Typography>
        </Box>
    );
}
