import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// project imports
import AuthWrapper1 from './tracking/AuthWrapper1';
import AuthCardWrapper from './tracking/MainWrapper';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AnimateButton from 'ui-component/extended/AnimateButton';

import LogoRM from 'imagenes/logotipos.png';
// assets

// ================================|| TRACKING PHISIC ||================================ //

const MainPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const handleClick = (type) => {
        if (type === 0) {
            return navigate('/fisicos');
        } else {
            return navigate('/electronicos');
        }
    };

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <img className="imageLogo" alt="logo" src={LogoRM} />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="16px"
                                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                                    >
                                                        Seleccione el tipo de trámite a solicitar
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ mt: 2 }} style={{ display: 'grid' }}>
                                        <AnimateButton>
                                            <LoadingButton
                                                disableElevation
                                                fullWidth
                                                size="large"
                                                onClick={() => handleClick(0)}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Trámites físicos
                                            </LoadingButton>
                                        </AnimateButton>
                                        <hr />
                                        <AnimateButton>
                                            <LoadingButton
                                                disableElevation
                                                fullWidth
                                                size="large"
                                                onClick={() => handleClick(1)}
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Trámites electrónicos
                                            </LoadingButton>
                                        </AnimateButton>
                                    </Box>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default MainPage;
