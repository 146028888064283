import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import IconButton from '@mui/material/IconButton';

export const LikeButtonFilled = ({ handleLike }) => {
    return (
        <IconButton size="small" className="circleButton" onClick={handleLike}>
            <ThumbUpIcon style={{ color: 'white' }} fontSize="large" />
        </IconButton>
    );
};
