const moment = require('moment');
const Green = '#00800094',
    Gray = '#aba6a6',
    Yellow = '#e3e386',
    Red = '#ff5050';
// const Green = 'verde', Gray = 'gris', Yellow = 'amarillo', Red = 'rojo';

// This methods returns an array with objects with the same format
export const getStepObject = (step) => ({
    logs: [...step.steps],
    user: 'PENDIENTE',
    start: 'PENDIENTE',
    end: 'PENDIENTE',
    title: step.type.toUpperCase(),
    comentario: step.comentario
});

// This method returns the actual date
export const getActualDate = () => {
    const date = new Date();

    const ss = date.getSeconds();
    const mm = date.getMinutes();
    const hh = date.getHours();

    const yy = date.getFullYear();
    const mo = date.getMonth() + 1; // 0 = January, 1 = February ...
    const dd = date.getDate();
    return `${yy}/${mo}/${dd} ${hh}:${mm}:${ss}`;
};

// This method sets how long each step
export const setTimestamp = (steps) => {
    for (const step of steps) {
        if (step.logs.length === 0 || step.logs.length === 1) {
            step.duration = '0 día(s), 0 hora(s) y 0 minuto(s)';
        } else {
            const begin = step.logs[0].fecha;
            const end = step.logs[step.logs.length - 1].fecha;
            step.duration = getDiferenceBetweenDates(begin, end);
        }
    }
    return steps;
};

// This method sets the color for each operation in operations
export const setColorOperationSteps = (operations, isActive) => {
    const newOperations = [];
    for (const operation of operations) {
        const newOperation = {
            ...operation,
            color: operation.descripcion.toLowerCase().includes('rechazo') ? Red : Green
        };
        newOperations.push(newOperation);
    }
    if (isActive) newOperations[newOperations.length - 1].color = Yellow;
    return newOperations;
};

// This method returns the diference between two dates like: 0 día(s), 0 hora(s) y 0 minuto(s)
export const getDiferenceBetweenDates = (begin, end) => {
    // Dates with format: DD/MM/YY HH:mm:ss like string
    const beginDate = moment(begin, 'DD/MM/YYYY hh:mm:ss');
    const endDate = moment(end, 'YYYY/MM/DD hh:mm:ss');
    const m3 = endDate.diff(beginDate, 'minutes');
    const days = Math.floor(m3 / 1440);
    const hours = Math.floor((m3 % 1440) / 60);
    const minutes = Math.floor((m3 % 1440) % 60);
    return `${days} día(s), ${hours} hora(s) y ${minutes} minuto(s)`;
};

// This method gets the time between the begin to the last step active or finished
export const getTimeOfProcedure = (steps) => {
    if (steps.length === 0) return `0 día(s), 0 hora(s) y 0 minuto(s)`;
    let timeBegin, timeEnd;
    timeBegin = steps[0].start;
    for (let i = steps.length - 1; i >= 0; i--) {
        const element = steps[i];
        if (element.logs.length !== 0) {
            if (element.logs[0].end === 'PENDIENTE') {
                break;
            }
            const logsEnd = element.logs;
            const lastLog = logsEnd[logsEnd.length - 1];
            timeEnd = lastLog.end;
            break;
        }
    }
    if (typeof timeEnd === 'undefined') {
        timeEnd = getActualDate();
    }
    return getDiferenceBetweenDates(timeBegin, timeEnd);
};

const getColorPaso = (vigente, en_espera, nombre, rechazo) => {
    if (rechazo) return Red;
    // if (vigente && nombre !== 'COMPLETADO' && nombre != 'DESCARGADO') {
    //    return Yellow;
    //}
    if (en_espera) return Gray;
    return Green;
};

// This method to order each step of the phisic procedure
export const formatSteps = (timeline) => {
    if (timeline.length === 0) return [];
    const data = [...timeline];
    for (let i = 0; i < data.length; i++) {
        const step = data[i];
        if (step.logs.length !== 0) {
            step.user = step.logs[0].user;
            step.start = step.logs[0].start;
            step.end = step.logs[0].end;
            step.comentario = step.logs[0].comentario;
        }
        for (let j = 0; j < step.logs.length; j++) {
            const log = step.logs[j];
            log.color = getColorPaso(log.vigente, log.en_espera, step.type, log.rechazo);
        }
    }
    return data;
};

// This method return a clean data
export const clearData = (timeline) => {
    if (timeline.length === 0) return [];
    let array = [];
    for (let i = 0; i < timeline.length; i++) {
        const log = timeline[i];
        if (log.type.toUpperCase() === 'RECHAZO') {
            continue;
        }
        if (log.type.toUpperCase() === 'COMPLETADO' && log.steps.length === 0) {
            const obj = {
                steps: [
                    {
                        user: 'PENDIENTE',
                        start: 'PENDIENTE',
                        end: 'PENDIENTE',
                        color: Gray,
                        comentario: 'S/C',
                        vigente: false,
                        en_espera: true
                    }
                ],
                type: log.type,
                comentario: 'S/C'
            };
            array.push(getStepObject(obj));
        }
        if (log.steps.length !== 0) {
            array.push(getStepObject(log));
        }
    }
    return formatSteps(array);
};
