const moment = require('moment');
const Green = '#00800094',
    Gray = '#aba6a6',
    Yellow = '#e3e386',
    Red = '#ff5050';
// const Green = 'verde', Gray = 'gris', Yellow = 'amarillo', Red = 'rojo';

// This methods returns an array with objects with the same format
export const getStepObject = (steps, i) => ({
    color: Green,
    operations: [],
    active: false,
    duration: null,
    usuario: 'En proceso',
    fecha: 'En proceso',
    begin: 'En proceso',
    task: 'En proceso',
    end: 'En proceso',
    logs: [...steps],
    step: i + 1,
    title: getTitle(i + 1, steps)
});

export const getLogObject = (log) => ({
    anio_exp: log.anio_exp,
    descripcion: log.descripcion,
    fecha: log.fecha,
    num_exp: log.num_exp,
    num_tramite: log.num_tramite,
    observaciones: log.observaciones,
    usuario: log.usuario,
    begin: 'En proceso',
    task: 'En proceso',
    end: 'En proceso'
});

// This method returns the title of the step, it depends of a number of step
export const getTitle = (i, step) => {
    switch (i) {
        case 1:
            return 'CONTRASEÑA EMITIDA';
        case 2:
            return 'ASIGNADO';
        case 3:
            return 'OPERANDO TRÁMITE';
        case 4:
            if (step.length === 0) return 'INSCRIPCIÓN ELECTRÓNICA OPERADA';
            if (step[0].descripcion.toLowerCase().includes('modificacion electronica operada')) return 'MODIFICACIÓN ELECTRÓNICA OPERADA';
            return 'INSCRIPCIÓN ELECTRÓNICA OPERADA';
    }
};

// This method returns the actual date
export const getActualDate = () => {
    const date = new Date();

    const ss = date.getSeconds();
    const mm = date.getMinutes();
    const hh = date.getHours();

    const yy = date.getFullYear();
    const mo = date.getMonth() + 1; // 0 = January, 1 = February ...
    const dd = date.getDate();
    return `${dd}/${mo}/${yy} ${hh}:${mm}:${ss}`;
};

// This method orders the information by date
export const parseBitacora = (bitacora) => {
    if (bitacora.length === 0) return [];
    for (let i = 0; i < bitacora.length; i++) {
        bitacora[i].fecha = moment(bitacora[i].fecha, 'DD/MM/YYYY HH:mm:ss');
    }
    const newarr = bitacora.sort((a, b) => {
        return a.fecha - b.fecha;
        // return moment(a.fecha).diff(b.fecha);
    });
    for (let i = 0; i < newarr.length; i++) {
        newarr[i].fecha = newarr[i].fecha.format('DD/MM/YYYY HH:mm:ss');
    }
    return newarr;
};

// This method sets the color of each step
export const setColorsCards = (steps) => {
    for (let i = 0; i < steps.length; i++) {
        // Changes the values for objects with the same structure
        steps[i] = getStepObject(steps[i], i);
    }
    for (let i = steps.length - 1; i >= 0; i--) {
        const actualStep = steps[i];
        if (i === 3) {
            if (actualStep.logs.length === 0 && steps[i - 1].logs.length !== 0) {
                actualStep.color = Yellow;
                actualStep.active = true;
                break;
            } else if (steps[i - 1].logs.length === 0) {
                actualStep.color = Gray;
            }
        } else if (i === 2) {
            if (actualStep.logs.length === 0 && steps[i - 1].logs.length !== 0) {
                actualStep.color = Yellow;
                actualStep.active = true;
                break;
            } else if (steps[i - 1].logs.length === 0) {
                actualStep.color = Gray;
            }
        } else if (i === 1) {
            if (actualStep.logs.length === 0 && steps[i - 1].logs.length !== 0) {
                actualStep.color = Yellow;
                actualStep.active = true;
                break;
            } else if (steps[i - 1].logs.length !== 0 && steps[i + 1].logs.length === 0) {
                actualStep.color = Gray;
            }
        } else if (i === 0) {
            if (actualStep.logs.length === 0 && steps[i + 1].logs.length === 0) {
                actualStep.color = Yellow;
                actualStep.active = true;
            }
        }
    }
    steps[2].operations = steps[2].logs.length === 0 ? [] : setColorOperationSteps(steps[2].logs, steps[2].active);
    steps[3].operations = steps[3].logs.length === 0 ? [] : setColorOperationSteps(steps[3].logs, steps[3].active);
    if (steps[2].logs.length !== 0) {
        const logs = steps[2].logs;
        if (logs[logs.length - 1].descripcion.toLowerCase().includes('rechazo')) {
            steps[2].color = Red;
        }
    }
    return steps;
};

// This method sets the user who is doing a card and the thime between the begin to end of a card
export const setUsersAndDates = (steps) => {
    for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        if (step.color === Gray) {
            continue;
        } else {
            if (step.color === Yellow) {
                if (step.logs.length === 0) {
                    continue;
                } else {
                    step.usuario = step.logs[0].usuario;
                    step.fecha = step.logs[step.logs.length - 1].fecha;
                }
            } else {
                if (step.logs.length === 0 && steps[i + 1].logs.length !== 0) {
                    step.usuario = steps[i + 1].logs[0].usuario;
                    step.fecha = steps[i + 1].logs[steps[i + 1].logs.length - 1].fecha;
                } else if (step.logs.length !== 0) {
                    step.usuario = step.logs[0].usuario;
                    step.fecha = step.logs[step.logs.length - 1].fecha;
                }
            }
        }
    }
    return steps;
};

// This method sets how long each step
export const setTimestamp = (steps) => {
    for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        if (step.logs.length === 0 || step.logs.length === 1) {
            step.duration = '0 día(s), 0 hora(s) y 0 minuto(s)';
            step.begin = step.fecha;
            if (step.color === Green) {
                step.end = i === steps.length - 1 ? step.begin : steps[i + 1].fecha;
                step.task = getDiferenceBetweenDates(step.begin, step.end);
            }
        } else {
            const begin = step.logs[0].fecha;
            step.begin = begin;
            const end = step.logs[step.logs.length - 1].fecha;
            step.end = end;
            step.task = getDiferenceBetweenDates(begin, end);
        }
    }
    return steps;
};

// This method sets the color for each operation in operations
export const setColorOperationSteps = (operations, isActive) => {
    const newOperations = [];
    for (const operation of operations) {
        const newOperation = {
            ...operation,
            color: operation.descripcion.toLowerCase().includes('rechazo') ? Red : Green
        };
        newOperations.push(newOperation);
    }
    if (isActive) newOperations[newOperations.length - 1].color = Yellow;
    return newOperations;
};

// This method returns the diference between two dates like: 0 día(s), 0 hora(s) y 0 minuto(s)
export const getDiferenceBetweenDates = (begin, end) => {
    // Dates with format: DD/MM/YY HH:mm:ss like string
    const beginDate = moment(begin, 'DD/MM/YYYY hh:mm:ss');
    const endDate = moment(end, 'DD/MM/YYYY hh:mm:ss');
    const m3 = endDate.diff(beginDate, 'minutes');
    const days = Math.floor(m3 / 1440);
    const hours = Math.floor((m3 % 1440) / 60);
    const minutes = Math.floor((m3 % 1440) % 60);
    return `${days} día(s), ${hours} hora(s) y ${minutes} minuto(s)`;
};

// This method gets the time between the begin to the last step active or finished
export const getTimeOfProcedure = (steps) => {
    if (steps.length === 0) return `0 día(s), 0 hora(s) y 0 minuto(s)`;
    let timeBegin, timeEnd;
    timeBegin = steps[0].logs.length === 0 ? steps[1].logs[0].fecha : steps[0].logs[0].fecha;
    const found = steps.find((item) => item.active === true);
    if (typeof found !== 'undefined') {
        if (found.logs.length !== 0) {
            timeEnd = found.logs[found.logs.length - 1].fecha;
        } else {
            timeEnd = getActualDate();
        }
    } else {
        if (steps[3].color === Green) {
            timeEnd = steps[3].fecha;
        } else {
            timeEnd = getActualDate();
        }
    }
    return getDiferenceBetweenDates(timeBegin, timeEnd);
};

// This method returns the number of active step
export const getActiveStep = (steps) => {
    if (steps.length === 0) return 1;
    let valueIndex = 1;
    for (let i = 0; i < steps.length; i++) {
        if (steps[i].active) {
            valueIndex = steps[i].step;
            return valueIndex;
        }
    }
    return 5;
};

// This method to order each step of the phisic procedure
export const orderSteps = (bitacora, type) => {
    if (bitacora.length === 0) return [];
    const step1 = [],
        step2 = [],
        step3 = [],
        step4 = [];
    let flag = 0;
    for (let i = 0; i < bitacora.length; i++) {
        const actualLog = { ...bitacora[i] };
        actualLog.descripcion = actualLog.descripcion.replace('.', '').toLowerCase(); // Clean the data
        if (flag === 0) {
            //step 1
            if (actualLog.descripcion === 'contrase¿a emitida') {
                step1.push(bitacora[i]);
                flag = 1;
            }
        } else if (flag === 1) {
            if (actualLog.descripcion.includes('asignado')) {
                step2.push(bitacora[i]);
                flag = 2;
            }
        } else if (flag === 2) {
            if (!actualLog.descripcion.includes('inscripcion electronica operada' && 'modificacion electronica operada')) {
                if (
                    bitacora[i].descripcion.toLowerCase().includes('expediente se traslada a archivo') ||
                    bitacora[i].descripcion.toLowerCase().includes('expediente archivado')
                )
                    continue;
                if (
                    type.toUpperCase() === 'INSCRIPCIÓN DE EMPRESAS' &&
                    actualLog.descripcion.toLowerCase().includes('solicitud de patente electrónica')
                ) {
                    step4.push(bitacora[i]);
                } else {
                    step3.push(getLogObject(bitacora[i]));
                }
            } else {
                step4.push(bitacora[i]);
                flag = 2;
            }
        }
    }
    for (let i = 0; i < step3.length; i++) {
        const step = step3[i];
        step.begin = step.fecha;
        const begin = step.fecha;
        let end = '';
        if (i < step3.length - 1) {
            step.end = step3[i + 1].fecha;
            end = step.end;
        } else {
            step.end = step.fecha;
            end = step.end;
        }
        step.task = getDiferenceBetweenDates(begin, end);
    }
    for (let i = 0; i < step4.length; i++) {
        const step = step4[i];
        step.begin = step.fecha;
        const begin = step.fecha;
        let end = '';
        if (i < step4.length - 1) {
            step.end = step4[i + 1].fecha;
            end = step.end;
        } else {
            step.end = step.fecha;
            end = step.end;
        }
        step.task = getDiferenceBetweenDates(begin, end);
    }
    return [step1, step2, step3, step4];
};

// This method gets a date with this format: 0 día(s), 0 hora(s) y 0 minuto(s), and returns an array with the values like this: [0,0,0]
const parseDate = (fecha) => {
    let date = fecha.replace(/\(s\)/g, '').replace('día', '').replace('hora', '').replace('minuto', '').replace('y', ',').replace(/ /g, '');
    date = date.split(',');
    date = date.map((number) => Number(number));
    return date;
};

// This method substract two times, dateA - dateB, both with this format: [0,0,0] = [dd,hh,mm] and returns an array like: [0,0,0] = [dd,hh,mm]
const restDates = (dateA, dateB) => {
    let days = dateA[0] - dateB[0];
    let hours = dateA[1] - dateB[1];
    let minutes = dateA[2] - dateB[2];
    if (minutes < 0) {
        minutes = 60 + minutes;
        hours -= 1;
    }
    if (hours < 0) {
        hours = 24 + hours;
        days = days - 1;
    }
    if (days < 0) {
        return [0, 0, 0];
    }
    return [days, hours, minutes];
};

// This method returns the time of procedure without the time between rejections
export const timeWithoutRejection = (steps, date) => {
    if (steps.length === 0 || date === '0 día(s), 0 hora(s) y 0 minuto(s)') return '0 día(s), 0 hora(s) y 0 minuto(s)';
    let totalDate = parseDate(date); // [0,0,0]
    const operations = steps[2].operations;
    let dateBeginReject = ''; // String like: 16/11/2021 10:57:56
    for (let i = 0; i < operations.length; i++) {
        const operation = operations[i];
        if (operation.descripcion.toLowerCase().includes('rechazo')) {
            dateBeginReject = operation.fecha;
            continue;
        }
        if (!operation.descripcion.toLowerCase().includes('rechazo') && dateBeginReject !== '') {
            const diference = getDiferenceBetweenDates(dateBeginReject, operation.fecha);
            const parsed = parseDate(diference);
            totalDate = restDates(totalDate, parsed);
            dateBeginReject = '';
        }
    }
    if (dateBeginReject !== '' && steps[3].logs.length !== 0) {
        const diference = getDiferenceBetweenDates(dateBeginReject, steps[3].logs[0].fecha);
        const parsed = parseDate(diference);
        totalDate = restDates(totalDate, parsed);
    }
    return `${totalDate[0]} día(s), ${totalDate[1]} hora(s) y ${totalDate[2]} minuto(s)`;
};

export const parseData = (bitacora, type) => {
    const orderByDates = parseBitacora(bitacora);
    const stepsOrdered = orderSteps([...orderByDates], type);
    const CardsColorized = setColorsCards([...stepsOrdered]);
    const CardsWithUser = setUsersAndDates([...CardsColorized]);
    const CardsWithDuration = setTimestamp([...CardsWithUser]);
    return CardsWithDuration;
};
