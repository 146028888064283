import * as React from 'react';

import { styled } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary.main} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -195
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary.main} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

export default function StepperOperations({ operations, i }) {
    return (
        <Stepper activeStep={operations.length} orientation="vertical" nonLinear={false}>
            {operations.map((item, j) => (
                <Step key={`${i}-${j}`} expanded={true}>
                    <StepLabel>{item.descripcion}</StepLabel>
                    <StepContent>
                        <CardWrapper
                            sx={{ marginBottom: -3, backgroundColor: item.color, textAlign: 'left' }}
                            contentSX={{ paddingTop: 0, paddingBottom: '0px !important' }}
                            content={true}
                            key={`item-${i}`}
                        >
                            <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                Operador: {item.usuario}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                Ingreso a paso: {item.fecha}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                Tiempo del paso: {item.task}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                Finalización del paso: {item.end}
                            </Typography>
                            {item.observaciones !== ' ' && (
                                <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                    Observaciones: {item.observaciones}
                                </Typography>
                            )}
                        </CardWrapper>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
}
