import React from 'react';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import IconButton from '@mui/material/IconButton';

export const DislikeButtonUnfilled = ({ handleDisLike }) => {
    return (
        <IconButton size="small" className="circleButton" onClick={handleDisLike}>
            <ThumbDownOffAltOutlinedIcon style={{ color: 'white' }} fontSize="large" />
        </IconButton>
    );
};
