// material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="space-between">
        <Typography
            variant="subtitle2"
            component={Link}
            href="https://www.registromercantil.gob.gt/webrm/"
            target="_blank"
            underline="hover"
        >
            registromercantil
        </Typography>
        <Typography variant="subtitle2" component={Link} target="_blank" underline="hover">
            &copy;
        </Typography>
    </Stack>
);

export default AuthFooter;
