import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import StepsTimelineElectronics from './trackingElectronic/ElectronicsTimeline';

export default function Pasos({ setMostrarPasos, pasos }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeStepName, setActiveStepName] = React.useState('');

    return (
        <Box sx={{ maxWidth: 600 }}>
            <Typography variant="h3" textAlign="center">
                {pasos.tipo_tramite[0].desc}
            </Typography>
            <Typography variant="h4" textAlign="center">
                Número de solicitud: {pasos.num_tramite}
            </Typography>
            <Typography variant="h6" textAlign="center">
                Estado actual: {pasos.timeline[activeStep].type}
            </Typography>
            <Divider />
            <Timeline position="alternate" className="no-select-user">
                <StepsTimelineElectronics timeline={pasos.timeline} activeStep={activeStep} />
            </Timeline>
        </Box>
    );
}
