import * as React from 'react';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { styled } from '@mui/material/styles';
import TimelineDot from '@mui/lab/TimelineDot';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Typography from '@mui/material/Typography';
import MainCard from 'ui-component/cards/MainCard';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import StepperOperations from './StepperOperations';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Reactions } from 'ui-component/Reactions';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.primary.main} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -195
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.primary.main} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

export default function StepsTimelinePhisic({ items, stepActive }) {
    const [expandOperations, setExpandOperations] = useState(false);
    const [expandInscripcion, setExpandInscripcion] = useState(false);
    const getIconStep = (i) => {
        if (i === 1) return <LooksOneIcon />;
        else if (i === 2) return <LooksTwoIcon />;
        else if (i === 3) return <Looks3Icon />;
        else if (i === 4) return <Looks4Icon />;
        else return <Looks5Icon />;
    };

    const getColorIconStep = (i) => {
        if (i <= stepActive) return 'primary';
        else return 'grey';
    };

    return (
        <div>
            {items.map((item, i) => (
                <TimelineItem key={i} className="no-select-user">
                    <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.primary">
                        {item.title}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color={getColorIconStep(i + 1)}>{getIconStep(i + 1)}</TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '1px', px: 2 }}>
                        <CardWrapper
                            sx={{ marginBottom: 2, backgroundColor: item.color, textAlign: 'left' }}
                            contentSX={{ paddingLeft: 1, paddingRight: 0, paddingTop: 0, paddingBottom: '0px !important' }}
                            content={true}
                            key={`item-${i}`}
                        >
                            <Typography className="typographyOperations" variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                Operador: {item.usuario}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                Ingreso a paso: {item.begin}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                Tiempo del paso: {item.task}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                Finalización del paso: {item.end}
                            </Typography>
                            <div className="likedButtons">
                                <Reactions cod_asignacion={`${item.title}-${item.usuario}`} />
                            </div>
                            {i === 2 && item.operations.length !== 0 && (
                                <div className="see-detail">
                                    <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                        Ver detalle:
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        className="circleButton"
                                        onClick={() => setExpandOperations(!expandOperations)}
                                    >
                                        <ExpandCircleDownRoundedIcon color="primary" fontSize="large" />
                                    </IconButton>
                                </div>
                            )}
                            {i === 3 && item.operations.length !== 0 && (
                                <div className="see-detail">
                                    <Typography variant="subtitle2" sx={{ color: 'black', mt: 0.5 }}>
                                        Ver detalle:
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        className="circleButton"
                                        onClick={() => setExpandInscripcion(!expandInscripcion)}
                                    >
                                        <ExpandCircleDownRoundedIcon color="primary" fontSize="large" />
                                    </IconButton>
                                </div>
                            )}
                        </CardWrapper>
                        {i === 2 && item.operations.length !== 0 && (
                            <Collapse in={expandOperations}>
                                <StepperOperations operations={item.operations} i={i} />
                            </Collapse>
                        )}
                        {i === 3 && item.operations.length !== 0 && (
                            <Collapse in={expandInscripcion}>
                                <StepperOperations operations={item.operations} i={i} />
                            </Collapse>
                        )}
                    </TimelineContent>
                </TimelineItem>
            ))}
        </div>
    );
}
