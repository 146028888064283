import React from 'react';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import IconButton from '@mui/material/IconButton';

export const DislikeButtonFilled = ({ handleDisLike }) => {
    return (
        <IconButton size="small" className="circleButton" onClick={handleDisLike}>
            <ThumbDownAltIcon style={{ color: 'white' }} fontSize="large" />
        </IconButton>
    );
};
