import React from 'react';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import IconButton from '@mui/material/IconButton';

export const LikeButtonUnfilled = ({ handleLike }) => {
    return (
        <IconButton size="small" className="circleButton" onClick={handleLike}>
            <ThumbUpOutlinedIcon style={{ color: 'white' }} fontSize="large" />
        </IconButton>
    );
};
