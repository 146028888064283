import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// routes
// import MainRoutes from './MainRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';

const Tracking = Loadable(lazy(() => import('views/pages/tracking/trackingElectronic/Tracking3')));
const TrackingUrl = Loadable(lazy(() => import('views/pages/tracking/trackingElectronic/TrackingUrl')));
import TrackingPhisic from 'views/pages/tracking/trackingPhisic/TrackingPhisic';
import TrackingPhisicUrl from 'views/pages/tracking/trackingPhisic/TrackingUrl';
import MainPage from 'views/pages/MainPage';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        {
            path: '/',
            element: <MainPage />
        },
        {
            path: '/electronicos/',
            element: <Tracking />
        },
        {
            path: '/electronicos/:id',
            element: <TrackingUrl />
        },
        {
            path: '/fisicos/',
            element: <TrackingPhisic />
        },
        {
            path: '/fisicos/:id',
            element: <TrackingPhisicUrl />
        }
    ]);
}
