import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery, FormHelperText } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import PhisicStepsWrapper from '../PhisicStepsWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import PasosURL from '../PasosURL';
import PasosFisicos from '../PasosFisicos';
import config from 'config';
import LogoRM from 'imagenes/logotipos.png';
// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const TrackingPhisicUrl = () => {
    const { id } = useParams();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [mostrarPasos, setMostrarPasos] = useState(false);
    const [pasos, setPasos] = useState(null);

    const [enviando, setEnviando] = useState(true);
    const [error, setErrors] = useState({ error: false, mensaje: '' });

    useEffect(() => {
        try {
            fetch(`${config.server}/service/checkTracking?data=${id}&tipo=fisico`, {
                method: 'GET'
            })
                .then((response) => response.json())
                .then((data) => {
                    // console.log('respuesta servicio', data);
                    if (data.tipo_tramite !== null && data.tipo_tramite !== undefined) {
                        console.log('valido');
                        setMostrarPasos(true);
                        setPasos(data);
                    } else {
                        setErrors({ error: true, mensaje: 'Error al procesar la solicitud, por favor intente mas tarde2' });
                    }
                    setEnviando(false);
                })
                .catch((error) => {
                    console.log('error al borrar las variables', error);
                    setEnviando(false);
                    setErrors({ error: true, mensaje: 'Error al procesar la solicitud, por favor intente mas tarde3' });
                });
        } catch (err) {
            console.log('error al borrar las variables', err);
            setEnviando(false);
            setErrors({ error: true, mensaje: 'Error al leer el codigo' });
        }
    }, []);
    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <PhisicStepsWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <img className="imageLogo" alt="logo" src={LogoRM} />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {enviando ? (
                                                <Grid item>
                                                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                        <Typography
                                                            variant="caption"
                                                            fontSize="16px"
                                                            textAlign={matchDownSM ? 'center' : 'inherit'}
                                                        >
                                                            Consultando tramite
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                        <Typography
                                                            variant="caption"
                                                            fontSize="16px"
                                                            textAlign={matchDownSM ? 'center' : 'inherit'}
                                                            style={{ color: 'red' }}
                                                        >
                                                            {error.mensaje}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {mostrarPasos ? (
                                        <Grid item xs={12}>
                                            <PasosFisicos pasos={pasos} />
                                        </Grid>
                                    ) : enviando ? (
                                        <CircularProgress />
                                    ) : null}
                                </Grid>
                            </PhisicStepsWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default TrackingPhisicUrl;
