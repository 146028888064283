import { useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import PhisicStepsWrapper from '../PhisicStepsWrapper';
import FormPhisics from '../auth-forms/FormPhisics';
import AuthFooter from 'ui-component/cards/AuthFooter';
import PasosFisicos from '../PasosFisicos';

import LogoRM from 'imagenes/logotipos.png';
import MainWrapper from '../MainWrapper';
// assets

// ================================|| TRACKING PHISIC ||================================ //

const TrackingPhisic = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [mostrarPasos, setMostrarPasos] = useState(false);
    const [pasos, setPasos] = useState(null);

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            {mostrarPasos ? (
                                <PhisicStepsWrapper>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item sx={{ mb: 3 }}>
                                            <Link to="#">
                                                <img className="imageLogo" alt="logo" src={LogoRM} />
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PasosFisicos setMostrarPasos={setMostrarPasos} pasos={pasos} />
                                        </Grid>
                                    </Grid>
                                </PhisicStepsWrapper>
                            ) : (
                                <MainWrapper>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item sx={{ mb: 3 }}>
                                            <Link to="#">
                                                <img className="imageLogo" alt="logo" src={LogoRM} />
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                direction={matchDownSM ? 'column-reverse' : 'row'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                        <Typography
                                                            variant="caption"
                                                            fontSize="16px"
                                                            textAlign={matchDownSM ? 'center' : 'inherit'}
                                                        >
                                                            Ingrese el número de solicitud para continuar
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormPhisics setMostrarPasos={setMostrarPasos} setPasos={setPasos} />
                                        </Grid>
                                    </Grid>
                                </MainWrapper>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default TrackingPhisic;
