import React from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import Steps from './trackingPhisic/PhisicSteps';

export default function PasosFisicos({ setMostrarPasos, pasos }) {
    const { tipo_tramite, num_tramite, bitacora } = pasos;
    return (
        <Box sx={{ maxWidth: 800 }}>
            <Typography variant="h3" textAlign="center">
                {tipo_tramite[0].desc}
            </Typography>
            <Typography variant="h4" textAlign="center">
                Número de trámite: {num_tramite}
            </Typography>
            <Divider />
            <Steps bitacora={bitacora} type={tipo_tramite[0].desc} />
            {setMostrarPasos && (
                <div>
                    <hr color="white" />
                    <AnimateButton>
                        <LoadingButton
                            disableElevation
                            fullWidth
                            size="large"
                            onClick={() => setMostrarPasos(0)}
                            variant="contained"
                            color="secondary"
                        >
                            Nueva consulta
                        </LoadingButton>
                    </AnimateButton>
                </div>
            )}
        </Box>
    );
}
